$.fn.serializeObject = function()
{
var o = {};
var a = this.serializeArray();
$.each(a, function() {
    if (o[this.name]) {
        if (!o[this.name].push) {
            o[this.name] = [o[this.name]];
        }
        o[this.name].push(this.value || '');
    } else {
        o[this.name] = this.value || '';
    }
});
return o;
};

SETTING = {
    init:function(){
        this.setWriteEvent();
    }
    ,setWriteEvent:function(){
        const btn = document.querySelector(".sitesetting_write_submit");
        const form = document.querySelector(".form_sitesetting_write_submit")
        btn.addEventListener('click',(e)=>{
            form.submit();
        })
        
    }
}

// window.addEventListener("DOMContentLoaded", function(){
//     SETTING.init();
// });

DATATABLE = {

    setTable:function(target){
        const lang_kor = {
            "decimal" : "",
            "emptyTable" : "데이터가 없습니다.",
            "info" : "_START_ - _END_ (총 _TOTAL_ 명)",
            "infoEmpty" : "0명",
            "infoFiltered" : "(전체 _MAX_ 명 중 검색결과)",
            "infoPostFix" : "",
            "thousands" : ",",
            "lengthMenu" : "show _MENU_ entries",
            "loadingRecords" : "로딩중...",
            "processing" : "처리중...",
            "search" : "search : ",
            "zeroRecords" : "검색된 데이터가 없습니다.",
            "paginate" : {
                "first" : "첫 페이지",
                "last" : "마지막 페이지",
                "next" : "다음",
                "previous" : "이전"
            },
            "aria" : {
                "sortAscending" : " :  오름차순 정렬",
                "sortDescending" : " :  내림차순 정렬"
            }
        }
        new DataTable(target,{
            order:[], //기본 정렬 없이
            language : lang_kor //or lang_eng

        });

    }
}

MEMBERINFO = {
    init:function(){
        
        console.log("고객 정보 입력 페이지 설정!");
        this.setEvent();
        this.setWriteEvent(); // post write 호출


        DATATABLE.setTable('#counselTable');
        DATATABLE.setTable('#contractTable');

        this.setTableEvent()

    }
    ,setTableEvent:function(){
        $(".clickable-row-counsel").on('click',function(e){
            e.preventDefault();

            //데이터 넣어주기
            let title = decodeURIComponent($(this).data("title"));
            let contents = decodeURIComponent($(this).data("contents"));
            let type = decodeURIComponent($(this).data("type"));
            let counselid = $(this).data("id");
            
            
            const counselpopup = document.querySelector("#counselpopup");

            $("#counselpopup input[name='counselid']").val(counselid);
            $("#counselpopup input[name='title']").val(title);
            $("#counselpopup textarea[name='contents']").val(contents);
            $("#counselpopup select[name='type']").val(type);

            counselpopup.style.display="block";   
            
            //sidepopup Event
             const sideW = $(window).innerWidth() - 1560;
            if($('#counselpopup').css('display') === 'block'){
                $("#contents_wrap .contents").css('width', '50%');
            }else {
                $("#contents_wrap .contents").css('width', 'auto');
            }
            
            //alert("이전 상담 자료 보기")

        });

        $(".member_delete_hard").on("click",function(e){
            e.preventDefault();
            if (!confirm("정말로 회원을 삭제 하시겠습니까?")) {
                // 취소(아니오) 버튼 클릭 시 이벤트
            } else {
                // 확인(예) 버튼 클릭 시 이벤트
                //alert("삭제진행!")
                var deleteid = $(this).data("id");
                axios.post(`/members/delete/${deleteid}`)
                .then(function (response) {
                    console.log(response);
                    if(response.data.result){
                        alert("삭제되었습니다.");
                        location.href="/members/list";
                    }else{
                        alert("삭제 실패 - 관리자문의");
                    }
                    
                    
                })
                .catch(function (error) {
                    console.log(error);
                    alert("삭제 실패 -  관리자문의")
                });
            }
        })

        $(".clickable-row-contract").on('click',function(e){
            e.preventDefault();

            //데이터 넣어주기
            let produc_title = decodeURIComponent($(this).data("productitle"));
            let subinfo_01 = decodeURIComponent($(this).data("subinfo01"));
            let subinfo_02 = decodeURIComponent($(this).data("subinfo02"));
            let subinfo_03 = decodeURIComponent($(this).data("subinfo03"));

            let paid_date_01 = decodeURIComponent($(this).data("paiddate01"));
            let paid_date_02 = decodeURIComponent($(this).data("paiddate02"));
            let paid_date_03 = decodeURIComponent($(this).data("paiddate03"));
            let paid_date_04 = decodeURIComponent($(this).data("paiddate04"));
            let paid_date_05 = decodeURIComponent($(this).data("paiddate05"));

            let paid_amount_01 = decodeURIComponent($(this).data("paidamount01"));
            let paid_amount_02 = decodeURIComponent($(this).data("paidamount02"));
            let paid_amount_03 = decodeURIComponent($(this).data("paidamount03"));
            let paid_amount_04 = decodeURIComponent($(this).data("paidamount04"));
            let paid_amount_05 = decodeURIComponent($(this).data("paidamount05"));

            let paid_status_01 = decodeURIComponent($(this).data("paidstatus01"));
            let paid_status_02 = decodeURIComponent($(this).data("paidstatus02"));
            let paid_status_03 = decodeURIComponent($(this).data("paidstatus03"));
            let paid_status_04 = decodeURIComponent($(this).data("paidstatus04"));
            let paid_status_05 = decodeURIComponent($(this).data("paidstatus05"));

            let paidplan = decodeURIComponent($(this).data("paidplan"));
            let ownership = decodeURIComponent($(this).data("ownership"));
            let options01 = decodeURIComponent($(this).data("options01"));
            let options02 = decodeURIComponent($(this).data("options02"));
            let price = decodeURIComponent($(this).data("price"));
            let deposit = decodeURIComponent($(this).data("deposit"));
            let etc = decodeURIComponent($(this).data("etc"));
            let memberplan = decodeURIComponent($(this).data("memberplan"));

            let bigo = decodeURIComponent($(this).data("bigo"));

            
            // let contents = decodeURIComponent($(this).data("contents"));
            // let type = decodeURIComponent($(this).data("type"));
            let contractid = $(this).data("id");
            
            
            const contractpopup = document.querySelector("#contractpopup");

            $("#contractpopup input[name='contractid']").val(contractid);
            $("#contractpopup input[name='produc_title']").val(produc_title);
            $("#contractpopup input[name='subinfo_01']").val(subinfo_01);
            $("#contractpopup input[name='subinfo_02']").val(subinfo_02);
            $("#contractpopup input[name='subinfo_03']").val(subinfo_03);

            $("#contractpopup input[name='paid_date_01']").val(paid_date_01);
            $("#contractpopup input[name='paid_date_02']").val(paid_date_02);
            $("#contractpopup input[name='paid_date_03']").val(paid_date_03);
            $("#contractpopup input[name='paid_date_04']").val(paid_date_04);
            $("#contractpopup input[name='paid_date_05']").val(paid_date_05);

            $("#contractpopup input[name='paid_amount_01']").val(paid_amount_01);
            $("#contractpopup input[name='paid_amount_02']").val(paid_amount_02);
            $("#contractpopup input[name='paid_amount_03']").val(paid_amount_03);
            $("#contractpopup input[name='paid_amount_04']").val(paid_amount_04);
            $("#contractpopup input[name='paid_amount_05']").val(paid_amount_05);

            $("#contractpopup select[name='paid_status_01']").val(paid_status_01);
            $("#contractpopup select[name='paid_status_02']").val(paid_status_02);
            $("#contractpopup select[name='paid_status_03']").val(paid_status_03);
            $("#contractpopup select[name='paid_status_04']").val(paid_status_04);
            $("#contractpopup select[name='paid_status_05']").val(paid_status_05);

            $("#contractpopup input[name='paidplan']").val(paidplan);
            $("#contractpopup input[name='ownership']").val(ownership);
            $("#contractpopup input[name='options01']").val(options01);
            $("#contractpopup input[name='options02']").val(options02);
            $("#contractpopup input[name='price']").val(price);
            $("#contractpopup input[name='deposit']").val(deposit);
            $("#contractpopup input[name='etc']").val(etc);
            $("#contractpopup input[name='memberplan']").val(memberplan);

            $("#contractpopup textarea[name='bigo']").val(bigo);
            // $("#counselpopup textarea[name='contents']").val(contents);
            // $("#counselpopup select[name='type']").val(type);

            contractpopup.style.display="block";   
            
            //sidepopup Event
            const sideW = $(window).innerWidth() - 1560;
            if($('#contractpopup').css('display') === 'block'){
                $("#contents_wrap .contents").css('width', '50%');
            }else {
                $("#contents_wrap .contents").css('width', 'auto');
            }
            
            
            //alert("이전 상담 자료 보기")

        });
    }
    ,setEvent:function(){
        const that = this;
        const btn = document.querySelector(".counsel_write") || null;
        const counselpopup = document.querySelector("#counselpopup");
        if(btn){
            btn.addEventListener('click',e=>{
            e.preventDefault();
            counselpopup.style.display="block";   

            //신규작성이기에 초기화
            $("#counselpopup input[name='counselid']").val("");
            $("#counselpopup input[name='title']").val("");
            $("#counselpopup textarea[name='contents']").val("");
            $("#counselpopup select[name='type']").val("");
            
            //sidepopup Event
                const sideW = $(window).innerWidth() - 1560;
                if($('#counselpopup').css('display') === 'block'){
                    $("#contents_wrap .contents").css('width', '50%');
                }else {
                    $("#contents_wrap .contents").css('width', 'auto');
                }
            })
        }

        $(".btn_popup_close").on("click",function(e){
            e.preventDefault();
            $(this).parents(".side_popup").find(".closeBtn").trigger("click");
        })

        const contract_btn = document.querySelector(".contract_write");
        const contractpopup = document.querySelector("#contractpopup");

        if(contract_btn){
            contract_btn.addEventListener('click',e=>{
                e.preventDefault();
                contractpopup.style.display="block";   

                
                
                //sidepopup Event
                const sideW = $(window).innerWidth() - 1560;
                if($('#contractpopup').css('display') === 'block'){
                    $("#contents_wrap .contents").css('width', '50%');
                }else {
                    $("#contents_wrap .contents").css('width', 'auto');
                }
                

             })
        }
        


        //  // 핸드폰 정규식 - 자동추가
        //  const phoneinput = document.querySelector(".phonenumber_input");
        //  phoneinput.addEventListener('change',function(e){
        //     e.preventDefault()
        //     phoneinput.value = phoneinput.value.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
        //  })

         
         // 고객 상담 등록
         const btn_counsel_write = document.querySelector(".btn_counsel_write");
         if(btn_counsel_write){
            btn_counsel_write.addEventListener("click",function(e){
                //alert("클릭!");
                e.preventDefault();
                that.counselWrite();
            })
         }
         

          // 고객 계약 등록
          const btn_contract_write = document.querySelector(".btn_contract_write");
          if(btn_contract_write){
            btn_contract_write.addEventListener("click",function(e){
                //alert("클릭!");
                e.preventDefault();
                that.contractWrite();
             })
          }
          

        // 계약 신규등록 창 열기
        const btn_contract_add = document.querySelector(".contract_add");
        if(btn_contract_add){
            btn_contract_add.addEventListener("click",function(e){
                //alert("클릭!");
                e.preventDefault();
                
                contractpopup.style.display="block";   

                $("#contractpopup input[name='contractid']").val("");
                $("#contractpopup input[name='produc_title']").val("");
                $("#contractpopup input[name='subinfo_01']").val("");
                $("#contractpopup input[name='subinfo_02']").val("");
                $("#contractpopup input[name='subinfo_03']").val("");

                $("#contractpopup input[name='paid_date_01']").val("");
                $("#contractpopup input[name='paid_date_02']").val("");
                $("#contractpopup input[name='paid_date_03']").val("");
                $("#contractpopup input[name='paid_date_04']").val("");
                $("#contractpopup input[name='paid_date_05']").val("");

                $("#contractpopup input[name='paid_amount_01']").val("");
                $("#contractpopup input[name='paid_amount_02']").val("");
                $("#contractpopup input[name='paid_amount_03']").val("");
                $("#contractpopup input[name='paid_amount_04']").val("");
                $("#contractpopup input[name='paid_amount_05']").val("");

                $("#contractpopup input[name='paid_status_01']").val("");
                $("#contractpopup input[name='paid_status_02']").val("");
                $("#contractpopup input[name='paid_status_03']").val("");
                $("#contractpopup input[name='paid_status_04']").val("");
                $("#contractpopup input[name='paid_status_05']").val("");

                $("#contractpopup input[name='paidplan']").val("");
                $("#contractpopup input[name='ownership']").val("");
                $("#contractpopup input[name='options01']").val("");
                $("#contractpopup input[name='options02']").val("");
                $("#contractpopup input[name='price']").val("");
                $("#contractpopup input[name='deposit']").val("");
                $("#contractpopup input[name='etc']").val("");
                $("#contractpopup input[name='memberplan']").val("");

                $("#contractpopup textarea[name='bigo']").val("");
                
                //sidepopup Event
                const sideW = $(window).innerWidth() - 1560;
                if($('#contractpopup').css('display') === 'block'){
                    $("#contents_wrap .contents").css('width', '50%');
                }else {
                    $("#contents_wrap .contents").css('width', 'auto');
                }
            })
        }
        
    }

    ,setWriteEvent:function(){
        const btn = document.querySelector(".members_write_submit");
        const form = document.querySelector(".form_members_write_submit")
        btn.addEventListener('click',e=>{
            // 필수 값 검사
            let formdata = $(".form_members_write_submit").serializeObject();
            console.log(formdata)
            if(!formdata.id || !formdata.username){
                alert("이름 과 id는 필수 입력 사항 입니다.")
            }else{
                //alert("저장");
                form.submit();
            }
            
        })

        
        
    }

    ,counselWrite:function(){
        
        
        let formdata  = $("#form_counselpopup").serializeObject();
        
        axios.post(`/members/counsel/write`, formdata)
        .then(function (response) {
            //console.log(response);
            location.reload(true);
            
        })
        .catch(function (error) {
            console.log(error);
            alert("업로드 안됨 -  관리자문의")
        });
    }

    ,contractWrite:function(){
        
        
        let formdata  = $("#form_contractpopup").serializeObject();
        
        axios.post(`/members/contract/write`, formdata)
        .then(function (response) {
            //console.log(response);
            location.reload(true);
            
        })
        .catch(function (error) {
            console.log(error);
            alert("업로드 안됨 -  관리자문의")
        });
    }
}

MANAGER = {
    init:function(){
        console.log("manager init")
        this.setSubmitEvent()
    }
    ,setSubmitEvent:function(){
        
        $(".btn_password_reset").on("click",function(e){
            e.preventDefault()
            
            let userid = $(this).data("id");
            let data = {
                userid
            }
            axios.post(`/userpassreset`, data).then(function (response) {
                console.log(response);
                alert("수정되었습니다. (임시비번 : 0000)");
                //location.reload(true);
            }).catch(function (error) {
                console.log(error);
                alert("변경 실패");
                
            });
        })

        console.log("setSubmitEvent");
        const btn = $(".adminuser_write_submit");
        const form = document.querySelector("#form_adminuser")
        btn.on('click',e=>{
            e.preventDefault();
            let tempProp1,tempProp2;
            tempProp1 = $(".group1selecter").prop("disabled");
            tempProp2 = $(".group2selecter").prop("disabled");
            $(".group1selecter").prop('disabled',false);
            $(".group2selecter").prop('disabled',false);
            let formdata = $("#form_adminuser").serializeObject();
            console.log(formdata)
            if(!formdata.id || !formdata.password || !formdata.name || !formdata.phone || !formdata.email || !formdata.group1 || !formdata.group2 || !formdata.level){
                alert("필수 입력 사항을 확인해주세요")
                $(".group1selecter").prop('disabled',tempProp1);
                $(".group2selecter").prop('disabled',tempProp2);
            }else{
                //alert("저장");
                form.submit();
            }  
        })
    }
}

AUTH = {
    init:function(){
        console.log("AUTH init");
        this.setAddUserEvent();


    }
    ,setAddUserEvent:function(){
        var that = this;
        
        $("#form-authcompanywrite").on("submit",function(e){
            $(".btn_addCompany_submit").trigger("click");
            e.preventDefault();
        });

        $("#form-authteamswrite").on("submit",function(e){
            $(".btn_addteams_wirte_submit").trigger("click");
            e.preventDefault();
        });
        $(".btn_addCompany").on("click",function(e){
            e.preventDefault();
            $(".addcompany_popup").show();
            alert("콘텐츠 관리회사 등록")
            
        })
        $(".btn_delete_team").on("click",function(e){
            e.preventDefault();
            alert("팀삭제 작업중입니다.");

            formdata = {
                company:$(this).data("id")
                ,team:$(this).data("team")
            }
            console.log(formdata);
            axios.post(`/auth/teamdelete`, formdata)
            .then(function (response) {
                location.reload(true);
            })
            .catch(function (error) {
                console.log(error);
                alert("등록 실패");
                location.reload(true);
            });
        });
        $(".btn_addCompany_submit").on("click",function(e){
            that.companyWrite();
        })
        $(".btn_addteams_wirte").on("click",function(e){
            e.preventDefault();
            let companyid = $(this).data("id");
            $(".addteams_popup").show();
            $(".addteams_popup input[name='company']").val(companyid);

            // let formdata  = $("#form-authteamswrite").serializeObject();
            // console.log(formdata,"!");

            // axios.post(`/auth/teamwrite`, formdata)
            // .then(function (response) {
            //     location.reload(true);
                
            // })
            // .catch(function (error) {
            //     console.log(error);
            //     alert("등록 실패");
            //     location.reload(true);
            // });
        })
        $(".btn_addteams_wirte_submit").on("click",function(e){
            e.preventDefault();
            
            let formdata  = $("#form-authteamswrite").serializeObject();
            console.log(formdata,"!");

            if(!formdata.teams){
                alert("팀명은 필수입니다.");
                return false;
                
            }

            axios.post(`/auth/teamwrite`, formdata)
            .then(function (response) {
                location.reload(true);
                
            })
            .catch(function (error) {
                console.log(error);
                alert("등록 실패");
                location.reload(true);
            });
        })
        $(".comapnyleaderadd").on("click",function(e){
            e.preventDefault(); 
            let companyid = $(this).data("id");

            $(".addleader_popup").show();
            $(".addleader_popup input[name='companyid']").val(companyid);
            $(".addleader_popup input[name='teamid']").val("");
        })
        $(".btn_addleader_submit").on("click",function(e){
            e.preventDefault();
            alert("회사 리더 등록");
        })

    }
    ,companyWrite:function(){
        
        let formdata  = $("#form-authcompanywrite").serializeObject();
        console.log(formdata,"!");

        if(!formdata.company){
            alert("회사명은 필수입니다.");
            return false;
            
        }
        
        axios.post(`/auth/write`, formdata)
        .then(function (response) {
            location.reload(true);
            
        })
        .catch(function (error) {
            console.log(error);
            alert("등록 실패");
            location.reload(true);
        });
    }
}

COMMUNITYWRITE = {
    init:function(){
       this.setDefaualtEvent();
    }
    ,setDefaualtEvent:function(){
        $(".selector_witer").on("change",function(e){
            
            const name_str = $(this).find("option:selected").data("id");
            const sex_str = $(this).find("option:selected").data("sex");
            const birthdate_str = $(this).find("option:selected").data("birthdate");

            $(".user_info .userinfo_id").html(name_str);
            $(".user_info .userinfo_sex").html(sex_str);
            $(".user_info .userinfo_birthdate").html(birthdate_str);

            $(".user_info").show();
        })

        

        //댓글 쓰기
        $(".comment_write .btn_comment_write").on("click",function(e){
            e.preventDefault()
            
            const $commentWrap = $(this).parents(".comment_write");
            let text = $commentWrap.find("textarea[name='commenttext']").val();
            if(!text)return;
            
            let writer = $commentWrap.find(".selector_commentwiter").val();
            let date = $commentWrap.find("input[name='date']").val();
            let time = $commentWrap.find("input[name='time']").val();
            console.log(text,writer,date,time);

            const postid = $(".deletePostBtn").data("post");
            if(!writer){
                alert("글쓴이 등록 필요")
                return;
            }
            const data = {
                text,writer,date,time,postid
            }
            console.log(data);

            axios.post(`/comment/write`,data)
            .then(function (response) {
                //reload
                location.reload();
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
                
            });


        })

        $(".btn_comment_cancel").on("click",function(e){
            e.preventDefault()
            alert("댓글 취소");
        })

        $(".btn_coment_write_depth").on("click",function(e){
            e.preventDefault();
            alert("대댓?");
            
            let writeHmlt = $(".comment_write_html_default").html();
            //console.log(writeHmlt);
            const comentswrap = $(this).parents(".comment_list");
            const comentparents = $(this).parents(".comment_box");
            const post = comentparents.data("id");
            const depth = comentparents.data("depth");
            console.log(post,depth)
            comentparents.after(`<div class="comment_write">${writeHmlt}</div>`);
            comentswrap.find(".comment_write .btn_comment_cancel").on("click",function(e){
                e.preventDefault()
                alert("delete");
            })

            //여기에 밑에 입력창을 뿌려준다.
            //after
        })


    }
}


